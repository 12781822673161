import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ActivitiesService } from 'src/app/services/activities.service';
import { CollectionManagerService } from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';

import { PaginationConfig } from 'src/app/models/paginationConfig';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

import { ConfigService } from 'src/app/services/core/config.service';

@Component({
  selector: 'app-banners-log',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {

  activitiesOriginal: any = [];
  banners: any = [];

  BANNER_STUDENT: any = {};
  BANNER_TEACHER: any = {};

  sortColumns = {
    'date': { type: 'string', direction: '' },
    'user': { type: 'string', direction: '' },
    'type': { type: 'string', direction: '' },
    'modifiedUsername': { type: 'string', direction: '' },
    'responsibleUsername': { type: 'string', direction: '' },
    'description': { type: 'string', direction: '' }
  };

  getTodayDate() {
    let today = new Date();
    let year = today.getFullYear();
    let month = String(today.getMonth() + 1).padStart(2, '0');
    let day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getStartOfMonthDate() {
    let today = new Date();
    let year = today.getFullYear();
    let month = String(today.getMonth() + 1).padStart(2, '0');
    let day = "01";
    return `${year}-${month}-${day}`;
  }

  startDate: any = this.getStartOfMonthDate();
  endDate: any = this.getTodayDate();

  paginationConfig: PaginationConfig = new PaginationConfig();

  constructor(
    public activitiesService: ActivitiesService,
    public collectionManager: CollectionManagerService,
    private modalService: NgbModal,
    private ngxLoader: NgxUiLoaderService,
    private authenticationService: AuthenticationService,
    private configService: ConfigService
  ) { }

  isAdmin: boolean;

  ngOnInit(): void {
    this.authenticationService.isCurrentUserAdmin().then((data) => {
      this.isAdmin = data;
    });

    this.BANNER_STUDENT = this.configService.get('banner_student');
    if (this.BANNER_STUDENT == null) {
      this.BANNER_STUDENT = {};
    }

    this.BANNER_TEACHER = this.configService.get('banner_teacher');
    if (this.BANNER_TEACHER == null) {
      this.BANNER_TEACHER = {};
    }

    // this.filterByDate();
    this.all();
  }

  removeTime(date) {
    if (date != null) {
      return date.replace(' 00:00:00', '');
    } else {
      return '';
    }
  }

  delete(banner) {
    let self = this;
    this.ngxLoader.start();
    this.activitiesService.delete('banners/delete/' + banner.id + '').then((response) => {
      self.ngxLoader.stop();
      self.all();
    }).catch(function (error) {
      self.ngxLoader.stop();
    });
  }

  isValidDate(d: any) {
    const date: any = new Date(d);
    if (date != "Invalid Date") {
      return true;
    }
  }

  filterByDate() {
    let self = this;
    if (this.isValidDate(this.startDate) && this.isValidDate(this.endDate)) {
      this.ngxLoader.start();

      let postData = {
        'startDate': self.startDate,
        'endDate': self.endDate
      };

      this.activitiesService.save('banners', postData).then((response: any) => {
        response = response.reverse();
        self.activitiesOriginal = response;
        self.banners = response;
        self.ngxLoader.stop();
      }).catch(function (error) {
        self.ngxLoader.stop();
      });
    }
  }

  all() {
    let self = this;
    this.ngxLoader.start();
    this.activitiesService.read('banners').then((response) => {
      self.activitiesOriginal = response;
      self.banners = response;


      self.banners.sort((a, b) => new Date(b.endAt).getTime() - new Date(a.endAt).getTime());


      self.ngxLoader.stop();
    }).catch(function (error) {
      self.ngxLoader.stop();
    });
  }

  sort(subject, collection) {
    if (!this.sortColumns.hasOwnProperty(subject))
      alert('Sorteer optie bestaat niet');

    for (let key in this.sortColumns) {
      if (key !== subject)
        this.sortColumns[key].direction = '';
    }

    let column = this.sortColumns[subject];
    this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');

    this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
  }

  search(event, collection) {
    let keyword = event.target.value;
    this.banners = this.activitiesOriginal.slice();
    if (keyword != '') {
      this.paginationConfig.currentPage = 1;
      this.banners = this.collectionManager.search(keyword, collection).slice();
    }
  }

  pageChanged($event) {
    this.paginationConfig.currentPage = $event;
  }

  convertTypeToHuman(str) {
    if (str == "PASSWORD_MODIFIED") {
      return "wachtwoord gewijzigd";
    } else if (str == "PROFILE_MODIFIED") {
      return "profiel gewijzigd";
    } else {
      return str;
    }
  }
}
