import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { UsersService } from '../../services/users.service';
import { ConfigService } from '../../services/core/config.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PaginationConfig } from 'src/app/models/paginationConfig';
import { ActivitiesService } from 'src/app/services/activities.service';
import { CollectionManagerService } from '../../services/core/collection-manager.service';
import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
	selector: 'app-users-form',
	templateUrl: './users-form.component.html',
	styleUrls: ['./users-form.component.scss']
})
export class UsersFormComponent implements OnInit {
	activitiesOriginal: any = [];
	activityLogs: any = [];
	sortColumns = {
		'date': { type: 'string', direction: '' },
		'modifiedUsername': { type: 'string', direction: '' },
		'responsibleUsername': { type: 'string', direction: '' },
		'type': { type: 'string', direction: '' },
		'description': { type: 'string', direction: '' }
	};

	startDate: any;
	endDate: any;

	paginationConfig: PaginationConfig = new PaginationConfig();

	error: boolean = false;
	item: any = {};
	roles = [];
	organisations: any = [];
	studyLevels = [];
	errors: any = [];
	mutateForm;
	errormessage = false;
	successmessage = false;
	changepassword = false;
	classrooms: any = [];

	tab: String = "account";

	constructor(
		public service: UsersService,
		private route: ActivatedRoute,
		private config: ConfigService,
		private location: Location,
		private modalService: NgbModal,
		private ngxLoader: NgxUiLoaderService,
		private activitiesService: ActivitiesService,
		public collectionManager: CollectionManagerService,
		private authenticationService: AuthenticationService
	) { }

	changeTab(name) {
		this.tab = name;
	}

	isAdmin: boolean;

	ngOnInit(): void {
	  this.authenticationService.isCurrentUserAdmin().then((data) => {
		this.isAdmin = data;
	  });
		let self = this;

		this.fetchItem();

		this.service.read('organisations').then((response) => {
			self.organisations = response;
		});
		this.service.read('classrooms').then((response) => {
			self.classrooms = response;
		});

		this.all();
	}

	boolToState(bool) {
		if(bool) {
			return "Ja";
		} else {
			return "Nee";
		}
	}

	fetchItem() {
		let id = this.route.snapshot.paramMap.get('id');

		let self = this;
		this.ngxLoader.start();
		this.service.read('users/details/' + id + '').then((response) => {
			self.roles = this.config.get('user_roles');
			self.studyLevels = this.config.get('study_levels');

			self.item = response;
			self.ngxLoader.stop();
		});
	}

	getClassrooms(organisationId) {
		return this.classrooms;
	}

	save() {
		let self = this;
		self.errors = [];
		self.successmessage = false;
		self.errormessage = false;

		self.item.changepassword = this.changepassword;

		this.service.save('users/save', self.item).then((response) => {
			self.item = response;
			self.ngxLoader.stop();
			self.successmessage = true;

			self.changepassword = false;
		}).catch(function (response) {
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
		});
	}

	checkChangepassword(event) {
		if (this.changepassword === true) {
			this.changepassword = false;
		} else {
			this.changepassword = true;
		}
	}

	backLink() {
		this.location.back();
	}


	isValidDate(d: any) {
		const date: any = new Date(d);
		if (date != "Invalid Date") {
			return true;
		}
	}

	filterByDate() {
		let id = this.route.snapshot.paramMap.get('id');

		let self = this;
		if (this.isValidDate(this.startDate) && this.isValidDate(this.endDate)) {
			this.ngxLoader.start();
			this.activitiesService.read('ActivityLog/user/' + id).then((response) => {
				self.activitiesOriginal = response;
				self.activityLogs = response;
				self.ngxLoader.stop();
			}).catch(function (error) {
				self.ngxLoader.stop();
			});
		}
	}

	all() {
		let id = this.route.snapshot.paramMap.get('id');
		
		let self = this;
		this.ngxLoader.start();
		this.activitiesService.read('ActivityLog/user/' + id).then((response) => {
			self.activitiesOriginal = response;
			self.activityLogs = response;
			self.ngxLoader.stop();
		}).catch(function (error) {
			self.ngxLoader.stop();
		});
	}

	sort(subject, collection) {
		if (!this.sortColumns.hasOwnProperty(subject))
			alert('Sorteer optie bestaat niet');

		for (let key in this.sortColumns) {
			if (key !== subject)
				this.sortColumns[key].direction = '';
		}

		let column = this.sortColumns[subject];
		this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');

		this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
	}

	search(event, collection) {
		let keyword = event.target.value;
		this.activityLogs = this.activitiesOriginal.slice();
		if (keyword != '') {
			this.paginationConfig.currentPage = 1;
			this.activityLogs = this.collectionManager.search(keyword, collection).slice();
		}
	}

	pageChanged($event) {
		this.paginationConfig.currentPage = $event;
	}

	confirmDeleteEntree(user) {
		let self = this;

		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je entree bij '+user.name+' wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				self.resetEntree(user);
				self.fetchItem();
			}
		});
	}

	resetEntree(user) {
		let self = this;
		this.ngxLoader.start();
		this.service.delete('users/resetExternalId/' + user.id + '').then((response) => {
			self.ngxLoader.stop();
			self.all();
		}).catch(function (error) {
			self.ngxLoader.stop();
		});
	}
}
