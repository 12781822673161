<div class="mb-3" *ngIf="inactiveUsers && inactiveUsers.length > 0 && currentUser && (currentUser['role'] == 'ADMIN')">
  <div class="alert" style="cursor:pointer;" routerLink="/user/inactive" [ngClass]="{ 
      'alert-danger' : inactiveUsers.length > 500, 
      'alert-primary': inactiveUsers.length <= 500 
    }" role="alert">
    Er zijn {{inactiveUsers.length}} gebruikers die vannacht verwijderd worden.
  </div>
</div>
<div class="row" *ngIf="currentUser && (currentUser['role'] == 'ADMIN' || currentUser['role'] == 'EDITOR')">
  <div class="col-lg-6">

    <h3 class="font-weight-bold">Synchroniseren met live</h3>
    <div class="list-group">
      <a href="javascript:;" (click)="syncLessons()" class="list-group-item list-group-item-action">
        <span class="sync-box">
          <i class="fas fa-check" *ngIf="syncLessonDone == true"></i>
          <i class="fas fa-circle-notch fa-spin" *ngIf="syncLessonLoading == true"></i>
        </span>
        Synchroniseer Wordpress-lessen <small *ngIf="syncLessonTotal > 0">({{syncLessonProgress}}/{{syncLessonTotal}})</small>
      </a>
      <a href="javascript:;" (click)="syncPages()" class="list-group-item list-group-item-action">
        <span class="sync-box">
          <i class="fas fa-check" *ngIf="syncPagesDone == true"></i>
          <i class="fas fa-circle-notch fa-spin" *ngIf="syncPagesLoading == true"></i>
        </span>
        Synchroniseer Wordpress-pagina's
      </a>
      <a href="javascript:;" (click)="syncTerms()" class="list-group-item list-group-item-action">
        <span class="sync-box">
          <i class="fas fa-check" *ngIf="syncTermsDone == true"></i>
          <i class="fas fa-circle-notch fa-spin" *ngIf="syncTermsLoading == true"></i>
        </span>
        Synchroniseer Schoolwoorden-begrippen
      </a>
    </div>

  </div>
  <div class="col-lg-6">

    <h3 class="font-weight-bold">Redactie</h3>
    <div class="list-group">
      <a [href]="backupMxUrl" routerLink="/check-quotes" class="list-group-item list-group-item-action">Controleer
        citaten</a>
      <a [href]="backupWordpressUrl" routerLink="/check-images"
        class="list-group-item list-group-item-action">Controleer afbeeldingen</a>
      <a [href]="backupMxUrl" routerLink="/check-youtube" class="list-group-item list-group-item-action">Controleer
        YouTube-video's</a>
    </div>

  </div>
  <!-- <div class="col-lg-4">

    <h3 class="font-weight-bold">Handmatige back-up</h3>
    <div class="list-group">
      <a [href]="backupMxUrl" class="list-group-item list-group-item-action">Database MXP</a>
      <a [href]="backupWordpressUrl" class="list-group-item list-group-item-action">Database Wordpress</a>
    </div>

  </div> -->
</div>
<div class="row" *ngIf="currentUser">
  <div class="col-lg-8 mt-5">
    <h3 class="font-weight-bold">Inkomend feedback</h3>
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Datum</th>
          <th scope="col">Feedback</th>
          <th scope="col">Gebruiker</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let feedback of getActiveItems() let i = index">
          <td>{{feedback.dateTimeCreated | date:'short'}}</td>
          <td><span *ngFor="let chapter of feedback['chapters'] let i = index" class="font-weight-bold"
              style="text-decoration:underline;"><a (click)="visitLessonFeedBack(feedback['id'],chapter['id'])">
                {{chapter['chapter']['name']}} / {{feedback['lesson']['name']}}
              </a></span> <br /> {{feedback.content}}</td>
          <td>{{feedback.email}} <small>{{ feedback['lessonContent'] != null ? feedback['lessonContent']['studyLevel'] :
              '' }}</small></td>
        </tr>
      </tbody>
    </table>
    <h3 class="font-weight-bold">Actieve banner student <button [routerLink]="['/banners/form/', BANNER_STUDENT.id]"
        class="btn btn-sm btn-primary">Bewerken</button></h3>
    <div class="container-fluid band p-3" *ngIf="BANNER_STUDENT != null" [style.backgroundColor]="BANNER_STUDENT.color"
      style="position: sticky; top: 50px;">
      <div class="container px-3">
        <div class="row">
          <div class="col-12 col-md-6 text-center">
            <img *ngIf="BANNER_STUDENT.image && !BANNER_STUDENT.youtube" class="img-fluid"
              alt="Methode M ⋆ Methode M ⋆ Dé methode voor burgerschap, maatschappijleer en maatschappijkunde"
              [src]="BANNER_STUDENT.image">

            <iframe style="aspect-ratio: 16 / 9; width: 100%;" *ngIf="BANNER_STUDENT.youtube" type="text/html"
              [src]="embed(BANNER_STUDENT.youtube)" frameborder="0"></iframe>
          </div>
          <div class="col-12 col-md-5 my-5 my-sm-0 d-flex">
            <div class="d-flex align-items-center">
              <div class="d-block">
                <div class="text-white">
                  <div class="section-title">
                    <h2 [innerHTML]="BANNER_STUDENT.title"></h2>
                    <p [innerHTML]="BANNER_STUDENT.body"></p>
                  </div>
                </div>
                <p class="mt-3"><a *ngIf="BANNER_STUDENT.buttonText" class="btn" [href]="BANNER_STUDENT.link"
                    title="Lees meer over Methode M" [style.color]="BANNER_STUDENT.buttonTextColor"
                    [style.backgroundColor]="BANNER_STUDENT.buttonBackgroundColor">{{BANNER_STUDENT.buttonText}}</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3 class="font-weight-bold mt-3">Actieve banner docent <button [routerLink]="['/banners/form/', BANNER_TEACHER.id]"
        class="btn btn-sm btn-primary">Bewerken</button></h3>
    <div class="container-fluid band p-3" *ngIf="BANNER_TEACHER != null" [style.backgroundColor]="BANNER_TEACHER.color"
      style="position: sticky; top: 50px;">
      <div class="container px-3">
        <div class="row">
          <div class="col-12 col-md-6 text-center">
            <img *ngIf="BANNER_TEACHER.image && !BANNER_TEACHER.youtube" class="img-fluid"
              alt="Methode M ⋆ Methode M ⋆ Dé methode voor burgerschap, maatschappijleer en maatschappijkunde"
              [src]="BANNER_TEACHER.image">

            <iframe style="aspect-ratio: 16 / 9; width: 100%;" *ngIf="BANNER_TEACHER.youtube" type="text/html"
              [src]="embed(BANNER_TEACHER.youtube)" frameborder="0"></iframe>
          </div>
          <div class="col-12 col-md-5 my-5 my-sm-0 d-flex">
            <div class="d-flex align-items-center">
              <div class="d-block">
                <div class="text-white">
                  <div class="section-title">
                    <h2 [innerHTML]="BANNER_TEACHER.title"></h2>
                    <p [innerHTML]="BANNER_TEACHER.body"></p>
                  </div>
                </div>
                <p class="mt-3"><a *ngIf="BANNER_TEACHER.buttonText" class="btn" [href]="BANNER_TEACHER.link"
                    title="Lees meer over Methode M" [style.color]="BANNER_TEACHER.buttonTextColor"
                    [style.backgroundColor]="BANNER_TEACHER.buttonBackgroundColor">{{BANNER_TEACHER.buttonText}}</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4">

    <h3 class="font-weight-bold mt-5">Configuratie</h3>
    <div class="list-group">

      <table>
        <tr class="list-group-item list-group-item-action">
          <td>Nieuwsmelding docenten:<br><small>{{PORTAL_NEWS}}</small></td>
        </tr>

        <tr class="list-group-item list-group-item-action">
          <td>Afbeelding homepage:<br><small>{{INTRO_IMAGE_URL}}</small></td>
        </tr>
        <tr class="list-group-item list-group-item-action">
          <td>Slogan homepage:<br><small>{{INTRO_TEXT}}</small></td>
        </tr>
        <tr class="list-group-item list-group-item-action">
          <td>Versie mx-front:<br><small>{{VERSION_INFO}}</small></td>
        </tr>
      </table>
    </div>

  </div>
</div>