<h1>
	<span (click)="backLink()" class="btn btn-info mr-2"><i class="fas fa-arrow-left"></i></span>
	<span *ngIf="!item['id']">Les toevoegen</span>
	<span *ngIf="item.id">Les wijzigen</span>
</h1>

<div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
<div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>

<section class="user-form mt-3" *ngIf="item">
	<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item.id" name="id">

	<div class="form-group row">
		<div class="col-md-2">
			<label class="col-form-label">Naam:</label>
		</div>
		<div class="col-md-10">
			<input type="text" *ngIf="item" class="form-control" id="name" required [(ngModel)]="item.name" name="name">
			<div class="error" *ngIf="errors.name">{{errors.name}}</div>
		</div>
	</div>
<div class="form-group row">
  <div class="col-md-2">
    <label class="col-form-label">Actief:</label>
  </div>
  <div class="col-md-10">
    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" id="active" name="active" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.active">
      <label class="custom-control-label" for="active"></label>
    </div>
  </div>
</div>
	<div class="form-group row">
		<div class="col-md-2">
			<label for="title" class="col-form-label">Omschrijving (max. 245 tekens):</label>
		</div>
		<div class="col-md-10">
			<textarea (keyup)="checkDescriptionLength()" *ngIf="item" class="form-control" [ngClass]="{ 'is-invalid': errors.description }" [(ngModel)]="item.description" id="description" name="description" placeholder="" required>{{item['description']}}</textarea>
			<div class="error" *ngIf="errors.description">{{errors.description}}</div>
		</div>
	</div>
<div class="form-group row">
  <div class="col-md-2">
    <label class="col-form-label">Vrij toegankelijk:</label>
  </div>
  <div class="col-md-10">
    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" id="free" name="free" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.free">
      <label class="custom-control-label" for="free"></label>
    </div>
  </div>
</div>

<div class="form-group row">
	<div class="col-md-2">
		<label for="title" class="col-form-label">Extern ID:</label>
	</div>
	<div class="col-md-10">
		<textarea *ngIf="item" class="form-control" [ngClass]="{ 'is-invalid': errors.baseExternalId }" [(ngModel)]="item.baseExternalId" id="baseExternalId" name="description" placeholder="" required>{{item['baseExternalId']}}</textarea>
		<div class="error" *ngIf="errors.baseExternalId">{{errors.baseExternalId}}</div>
	</div>
</div>

	<div class="form-group row">
		<div class="col-md-2">
			<label for="role">Type:</label>
		</div>
		<div class="col-md-10">
			<select name="role" [(ngModel)]="item.type" class="form-control">
				<option value="{{lessonType['key']}}" [selected]="item['type'] == lessonType['key']" *ngFor="let lessonType of lessonTypes">
					{{lessonType['name']}}
				</option>
			</select>
			<div class="error" *ngIf="errors.studyLevel">{{errors.studyLevel}}</div>
		</div>
	</div>

	<div class="form-group row">
		<div class="col-md-2">
			<label class="col-form-label">Schoolwoorden is actief:</label>
		</div>
		<div class="col-md-10">
			<div class="custom-control custom-switch">
			  <input type="checkbox" class="custom-control-input" id="enableDictionary" name="enableDictionary" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.enableDictionary">
			  <label class="custom-control-label" for="enableDictionary"></label>
			</div>
		</div>
	</div>


	<div class="form-group row">
		<div class="col-md-2">
			<label class="col-form-label">PDF-genereren is actief:</label>
		</div>
		<div class="col-md-10">
			<div class="custom-control custom-switch">
			  <input type="checkbox" class="custom-control-input" id="pdf" name="pdf" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.pdf">
			  <label class="custom-control-label" for="pdf"></label>
			</div>
		</div>
	</div>


	<div class="form-group row">
		<div class="col-md-12">
			<label class="col-form-label">Lessen:</label>
		</div>
		<section class="" *ngIf="!item['id']">
			<div class="col-md-12">
				<p>Voeg hier niveau's toe na het opslaan van de les.</p>
			</div>
		</section>
		<section class="" *ngIf="item['id']">
			<div class="col-md-12">
				<hr style="border:0;border-top:1px solid #EEEEEE;"/>

				<div *ngFor="let content of contents; let i = index">
					<div class="row" >
						<div class="col-1">
							{{(i+1)}}
						</div>
						<div class="col-3">
							<div class="upload-image-container">
								<img [src]="content['image']" style="max-width:206px;" *ngIf="content['imageSource'] === undefined || content['imageSource'] == ''"/>
								<img [src]="content['imageSource']" style="max-width:206px;" *ngIf="content['imageSource'] != ''"/>
							</div>
							<div class="upload-image-button">
								<input name="image" type="file" id="image-select-{{i}}" accept="image/*" (change)="handleImageChange($event, i)" />
								<button type="button" class="btn btn-sm btn-secondary text-small" (click)="triggerImageSelect($event,i)">Selecteer afbeelding</button>
								<button *ngIf="content['image']" type="button" class="btn btn-sm btn-danger text-small ml-2" (click)="confirmDeleteThumb(item, i)">Verwijder afbeelding</button>
							</div>
						</div>
						<div class="col-3">
							<input type="text" placeholder="YouTube link" *ngIf="item" class="form-control" [(ngModel)]="content.video" name="video">
							<input type="text" placeholder="Auteur" *ngIf="item" class="form-control mt-1" [(ngModel)]="content.author" name="author">
						</div>
						<div class="col-2">
							<input type="text" placeholder="Caption" *ngIf="item" class="form-control mb-1" [(ngModel)]="content.caption" name="caption">
							<select name="role" [(ngModel)]="content.studyLevel" class="form-control" placeholder="Niveau">
								<option value="{{studyLevel['value']}}" [selected]="content['studyLevel'] == studyLevel['value']" *ngFor="let studyLevel of studyLevels">
									{{studyLevel['name']}}
								</option>
							</select>
						</div>
						<div class="col-2">
							<input type="text" placeholder="Extern id" *ngIf="content" class="form-control" [(ngModel)]="content.externalId" name="externalId">
						</div>
						<div class="col-1">
							<span class="delete-link d-inline-block ml-3" (click)="confirmDeleteLessonContent(i)"><i class="fas fa-trash"></i></span>
						</div>
					</div>
					<hr style="border:0;border-top:1px solid #EEEEEE;"/>
				</div>
			</div>
			<div class="col-md-12">
				<button type="button" (click)="addLessonContent();" class="btn btn-secondary">Niveau toevoegen</button>
			</div>
		</section>
	</div>

	<div class="form-group row">
		<div class="col-md-2">
			<label class="col-form-label">Gekoppelde hoofdstukken:</label>
		</div>
		<div class="col-md-10">
			<span *ngFor="let chapter of item['chapters']; let isLast=last">
			<a routerLink="/chapter/form/{{chapter.chapterId}}" class="d-inline-block p-1">
				{{chapter['name']}} <small *ngIf="chapter['sectionName']">({{chapter['sectionName']}})</small>
			</a>{{isLast ? '' : ', '}}
			</span>
		</div>
	</div>

	<button (click)="save()" class="btn btn-primary">
		<span *ngIf="!item.id">Toevoegen</span>
		<span *ngIf="item.id">Opslaan</span>
	</button>

	<button *ngIf="item.id" (click)="syncLesson()" class="btn btn-secondary ml-2">
		<span>Synchroniseer</span>
	</button>
</section>
