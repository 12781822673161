import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { UsersService }  from '../../services/users.service';
import { ConfigService }  from '../../services/core/config.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CollectionManagerService }  from '../../services/core/collection-manager.service';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'app-users-answers',
  templateUrl: './users-answers.component.html',
  styleUrls: ['./users-answers.component.scss']
})
export class UsersAnswersComponent implements OnInit {
	
  user;
  answers;
  answersOriginal;

  sortColumns = {
  	'questionId' : { type: 'string', direction: ''},
  	'lessonTitle' : { type: 'string', direction: ''},
  	'questionTitle' : { type: 'string', direction: ''},
  	'value' : { type: 'string', direction: ''},
  	'createdAt' : { type: 'string', direction: ''},
  	'updatedAt' : { type: 'string', direction: ''}
  };


	constructor(
    public service: UsersService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private location: Location,
    public collectionManager: CollectionManagerService,
    private ngxLoader: NgxUiLoaderService,
    private authenticationService: AuthenticationService
) { }

isAdmin: boolean;

  ngOnInit(): void {
    this.authenticationService.isCurrentUserAdmin().then((data) => {
      this.isAdmin = data;
    });
    let self = this;
		let id = this.route.snapshot.paramMap.get('id');
    this.service.read('answers/list/'+id+'').then((response) => {
      self.user = response['user'];
      self.answers = response['answers'];

      self.answers.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

      self.answersOriginal = response['answers'];
    });
  }

  
  search (event, collection) {
    let keyword = event.target.value;
    this.answers = this.answersOriginal.slice();
    if (keyword != '') {
      this.answers = this.collectionManager.search(keyword, collection).slice();
    }
  }

	backLink() {
		this.location.back();
	}

  sort (subject, collection) {
    if(!this.sortColumns.hasOwnProperty(subject))
      alert('Sorteer optie bestaat niet');
      
    for (let key in this.sortColumns) {
      if (key !== subject)
          this.sortColumns[key].direction = '';
    }
    
    let column = this.sortColumns[subject];
    this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');
 	
    this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
  }
  
}
